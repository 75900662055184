* {
  padding: 0;
  margin: 0;
}

:root {
  --color-1: #ac92eb;
  --color-2: #4fc1e8;
  --color-3: #a0d568;
  --color-4: #ffce54;
  --color-5: #f55c47;
}

html,
body {
  font-size: 14px;
  overflow-x: hidden;
}

canvas#swift-canvas {
  position: absolute;
  bottom: 0;
  top: 0;
  width: 100%;
  height: -webkit-fill-available;
  display: inline-block;
  z-index: 9;
}

.back-responsive {
  background: rgba(0, 0, 0, 0.75);
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
a,
span {
  font-family: "Raleway", sans-serif;
}

h2 {
  font-size: 38px;
  font-weight: 600;
  color: #444;
  text-align: center;
  text-transform: uppercase;
  margin: 0;
  .dark-mode & {
    color: white;
  }
}

h2:after {
  color: #ef255f;
  display: block;
  margin: -3px auto;
}

h3 {
  font-weight: 600;
}

h4 {
  font-weight: bold;
  letter-spacing: 1px;
}

p {
  font-size: 14px;
  font-weight: 500;
  color: #666;
  line-height: 24px;
  letter-spacing: 0.7px;
  .dark-mode & {
    color: white;
  }
}

p.sub-title {
  text-align: center;
  width: 65%;
  margin: 0 auto;
  padding-bottom: 50px;
}

ul li {
  list-style: none;
}

a:focus,
a:hover,
a:active,
a:active:focus,
a {
  text-decoration: none;
  outline: none;
  box-shadow: none !important;
}

img {
  max-width: 100%;
  display: block;
}

button,
input {
  outline: none;
}

::-webkit-selection {
  /* Code for Google chrom */
  color: #fff;
  background: #ef255f;
}

::-moz-selection {
  /* Code for Firefox */
  color: #fff;
  background: #ef255f;
}

::-ms-selection {
  /* Code for Internet Explorer */
  color: #fff;
  background: #ef255f;
}

::selection {
  color: #fff;
  background: #ef255f;
}

.clear {
  clear: both;
}

a.btn {
  color: #fff !important;
  position: relative;
  letter-spacing: 2px;
  text-transform: uppercase;
  overflow: hidden;

  margin-top: 20px;

  border: 1px solid #ef255f;
  border-radius: 2px;
  background: none;
  vertical-align: middle;
  z-index: 99;
  width: 70px;
  height: 50px;
  padding-top: 10px;
}

button.btn {
  color: #fff !important;
  position: relative;
  letter-spacing: 2px;
  text-transform: uppercase;
  overflow: hidden;

  margin-top: 20px;

  border: 1px solid #ef255f;
  border-radius: 2px;
  background: none;
  vertical-align: middle;
  z-index: 99;
  width: 130px;
  height: 50px;
}

button.btn-z,
a.btn-z {
  z-index: 9;
}

button:focus,
a:focus {
  outline: none !important;
  box-shadow: none;
}
button.btn > span,
a.btn > span {
  vertical-align: middle;
}

button.btn::before,
a.btn::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 150%;
  height: 100%;
  background: #ef255f;
  z-index: -1;
  -webkit-transform: rotate3d(0, 0, 1, -45deg) translate3d(0, -3em, 0);
  -moz-transform: rotate3d(0, 0, 1, -45deg) translate3d(0, -3em, 0);
  -ms-transform: rotate3d(0, 0, 1, -45deg) translate3d(0, -3em, 0);
  transform: rotate3d(0, 0, 1, -45deg) translate3d(0, -3em, 0);
  -webkit-transform-origin: 0% 100%;
  -moz-transform-origin: 0% 100%;
  -ms-transform-origin: 0% 100%;
  transform-origin: 0% 100%;
  -webkit-transition: -webkit-transform 0.3s, opacity 0.3s,
    background-color 0.3s;
  -moz-transition: -webkit-transform 0.3s, opacity 0.3s, background-color 0.3s;
  -ms-transition: -webkit-transform 0.3s, opacity 0.3s, background-color 0.3s;
  transition: transform 0.3s, opacity 0.3s, background-color 0.3s;
}

button.btn:hover,
a.btn:hover {
  color: #fff;
  border-color: #ef255f;
  box-shadow: none;
}

button.btn:hover::before,
a.btn:hover::before {
  opacity: 1;
  background-color: #ef255f;
  -webkit-transform: rotate3d(0, 0, 1, 0deg);
  -moz-transform: rotate3d(0, 0, 1, 0deg);
  -ms-transform: rotate3d(0, 0, 1, 0deg);
  transform: rotate3d(0, 0, 1, 0deg);
  -webkit-transition-timing-function: cubic-bezier(0.2, 1, 0.3, 1);
  -moz-transition-timing-function: cubic-bezier(0.2, 1, 0.3, 1);
  -ms-transition-timing-function: cubic-bezier(0.2, 1, 0.3, 1);
  transition-timing-function: cubic-bezier(0.2, 1, 0.3, 1);
}

.quick-link {
  margin-left: 10px;
}

.quick-link:first-child {
  margin-left: 0;
}

section {
  .dark-mode & {
    background-color: #0e1217;
    color: white;
  }
}

/*----------------------------------------------
*   02. Loading
*----------------------------------------------*/

.Loading {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #fff;
  text-align: center;
  z-index: 999999999;
}

.Loading .content {
  position: fixed;
  top: 50%;
  left: 50%;
  margin-top: -50px;
}

.Loading .content > div {
  background-color: #ef255f;
  height: 50px;
  width: 6px;
  margin: 20% auto;
  display: inline-block;
  -webkit-animation: sk-stretchdelay 1.2s infinite ease-in-out;
  -moz-animation: sk-stretchdelay 1.2s infinite ease-in-out;
  -ms-animation: sk-stretchdelay 1.2s infinite ease-in-out;
  animation: sk-stretchdelay 1.2s infinite ease-in-out;
}

.Loading .content .rect2 {
  -webkit-animation-delay: -1.1s;
  -mpz-animation-delay: -1.1s;
  -ms-animation-delay: -1.1s;
  animation-delay: -1.1s;
}

.Loading .content .rect3 {
  -webkit-animation-delay: -1s;
  -moz-animation-delay: -1;
  -ms-animation-delay: -1;
  animation-delay: -1s;
}

.Loading .content .rect4 {
  -webkit-animation-delay: -0.9s;
  -moz-animation-delay: -0.9s;
  -ms-animation-delay: -0.9s;
  animation-delay: -0.9s;
}

.Loading .content .rect5 {
  -webkit-animation-delay: -0.8s;
  animation-delay: -0.8s;
}

@-webkit-keyframes sk-stretchdelay {
  0%,
  40%,
  100% {
    -webkit-transform: scaleY(0.4);
  }
  20% {
    -webkit-transform: scaleY(1);
  }
}

@keyframes sk-stretchdelay {
  0%,
  40%,
  100% {
    transform: scaleY(0.4);
    -webkit-transform: scaleY(0.4);
  }
  20% {
    transform: scaleY(1);
    -webkit-transform: scaleY(1);
  }
}

/*----------------------------------------------
*   02. To Top
*----------------------------------------------*/
.to_top {
  position: fixed;
  right: 20px;
  bottom: 55.5px;
  width: 35px;
  height: 35px;
  background: #111;
  text-align: center;
  line-height: 35px;
  cursor: pointer;
  z-index: 999;
  display: block;
  -webkit-transition: bottom 0.5s ease-in-out;
  -moz-transition: bottom 0.5s ease-in-out;
  -ms-transition: bottom 0.5s ease-in-out;
  transition: bottom 0.5s ease-in-out;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
}

.to_top.showme {
  opacity: 1;
  visibility: visible;
  pointer-events: all;
}

.to_top i {
  color: #fff;
}

.to_top:hover {
  position: fixed;
  right: 20px;
  bottom: 60px;
}

/*----------------------------------------------
*   03. Slider Background and header-content
*----------------------------------------------*/

.slider {
  position: relative;
  width: 100%;
  height: 100vh;
  min-height: 750px;
  max-height: 1000px;
  background: linear-gradient(rgba(18, 18, 18, 0.1), rgba(18, 18, 18, 0.6)),
    url("../image/J3.jpg") no-repeat center right fixed;
  background-size: cover;
  overflow: hidden;
  background-position-y: 20%;
  background-position-x: 75%;
  .dark-mode & {
    background: linear-gradient(rgba(18, 18, 18, 0.1), rgba(18, 18, 18, 0.6)),
      url("../image/J3.jpg") no-repeat center right fixed;
    background-size: cover;
    background-position-y: 20%;
    background-position-x: 75%;
  }

  -webkit-animation: bg-img 1.2s cubic-bezier(0.5, 0.6, 0.6, 1);
  -moz-animation: bg-img 1.2s cubic-bezier(0.5, 0.6, 0.6, 1);
  animation: bg-img 1.2s cubic-bezier(0.5, 0.6, 0.6, 1);
  -webkit-animation-fill-mode: backwards;
  -moz-animation-fill-mode: backwards;
  animation-fill-mode: backwards;
}

.slider .header-content {
  position: relative;
  top: 100px;
  padding-top: 50px;
}

.slider .header-content h1 {
  font-size: 5.5rem;
  // color: white;
  font-weight: 900;

  letter-spacing: 2px;
  margin: 0;

  background: linear-gradient(
    219deg,
    var(--color-1) 19%,
    transparent 19%,
    transparent 20%,
    var(--color-2) 20%,
    var(--color-2) 39%,
    transparent 39%,
    transparent 40%,
    var(--color-3) 40%,
    var(--color-3) 59%,
    transparent 59%,
    transparent 60%,
    var(--color-4) 60%,
    var(--color-4) 79%,
    transparent 79%,
    transparent 80%,
    var(--color-5) 80%
  );
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
  .dark-mode & {
    color: white;
    background: transparent;
  }
}

.myname-wrapper {
  // background: radial-gradient(
  //     circle at 1.4% 1.4%,
  //     var(--color-1) 0.8%,
  //     transparent 0.8%
  //   ),
  //   radial-gradient(circle at 5.5% 3%, var(--color-2) 0.45%, transparent 0.45%),
  //   radial-gradient(circle at 2.5% 3.5%, var(--color-3) 0.5%, transparent 0.5%),
  //   radial-gradient(
  //     circle at 4.5% 1.2%,
  //     var(--color-4) 0.25%,
  //     transparent 0.25%
  //   ),
  //   radial-gradient(circle at 98% 98%, var(--color-1) 0.8%, transparent 0.8%),
  //   radial-gradient(circle at 95% 95%, var(--color-2) 0.45%, transparent 0.45%),
  //   radial-gradient(
  //     circle at 94.5% 97.5%,
  //     var(--color-3) 0.5%,
  //     transparent 0.5%
  //   ),
  //   radial-gradient(
  //     circle at 98.5% 95.5%,
  //     var(--color-4) 0.25%,
  //     transparent 0.25%
  //   );
}

.slider .header-content h3.typed-text:before {
  content: "";
  position: absolute;
  background: #000;
  width: 3px;
  height: 30px;
}

.typed-text {
  min-height: 32px;
}

.typed-cursor:not(.fa-i-cursor) {
  display: none !important;
}

.slider .header-content h3 {
  font-size: 27px;
  font-weight: 700;
  color: #fff;
  letter-spacing: 2px;
  &.typed-text {
    margin-top: 8px;
    margin-bottom: 4px;
  }
  // -webkit-text-shadow: 0px 0px 6px rgba(0, 0, 0, 0.6);
  // -moz-text-shadow: 0px 0px 6px rgba(0, 0, 0, 0.6);
  // -ms-text-shadow: 0px 0px 6px rgba(0, 0, 0, 0.6);
  // text-shadow: 0px 0px 6px rgba(0, 0, 0, 0.6);
}

.slider .header-content h3.text-1 {
  font-size: 36px;
  font-weight: 700;
  color: #fff;
  .dark-mode & {
    color: rgb(252, 207, 77);
  }
}

.slider .header-content h3.typed-text i {
  color: #ef255f;
}

.slider .header-content span {
  font-size: 27px;
  font-weight: 700;
  color: #ffffff;
  letter-spacing: 2px;
  margin-left: 5px;
}

/*----------------------------------------------
*   04. Slider => Navbar Menu
*----------------------------------------------*/
.slider .navbar #toggle {
  display: block;
  width: 60px;
  height: 60px;
  margin: 30px auto 10px;
  position: relative;
  z-index: 9999;
  background: #ef255f;
  border-radius: 50%;
  float: right;
}

.slider .navbar #toggle span:after,
.slider .navbar #toggle span:before {
  content: "";
  position: absolute;
  left: 0;
  top: -6px;
}
.slider .navbar #toggle span:after {
  top: 6px;
}

.slider .navbar .on span:after,
.slider .navbar .on span:before {
  content: "";
  position: absolute;
  left: 0;
  top: -9px !important;
}
.slider .navbar .on span:after {
  top: 9px !important;
}

.slider .navbar #toggle span {
  position: relative;
  display: block;
  margin: 30px auto;
}

.slider .navbar #toggle span,
.slider .navbar #toggle span:after,
.slider .navbar #toggle span:before {
  width: 16px;
  height: 2px;
  background-color: #fff;
  transition: all 0.3s;
  backface-visibility: hidden;
}

/* on activation */
.slider .navbar #toggle.on span {
  background-color: transparent;
}
.slider .navbar #toggle.on span:before {
  -webkit-transform: rotate(45deg) translate(5px, 5px);
  -moz-transform: rotate(45deg) translate(5px, 5px);
  -ms-transform: rotate(45deg) translate(5px, 5px);
  transform: rotate(45deg) translate(5px, 5px);
}
.slider .navbar #toggle.on span:after {
  -webkit-transform: rotate(-45deg) translate(7px, -8px);
  -moz-transform: rotate(-45deg) translate(7px, -8px);
  -ms-transform: rotate(-45deg) translate(7px, -8px);
  transform: rotate(-45deg) translate(7px, -8px);
}
.slider .navbar #toggle.on + #menu {
  opacity: 1;
  visibility: visible;
  z-index: 99;
}

/* menu appearance*/
.slider .navbar #menu {
  position: absolute;
  right: 15px;
  top: 30px;
  color: #999;
  width: 60px;
  padding: 10px;
  padding-top: 70px;
  margin: auto;
  text-align: center;
  border-radius: 30px;
  background: #ef255f;
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.05);
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.7s;
}

.slider .navbar ul,
li,
li a {
  list-style: none;
  display: block;
  margin: 0;
  padding: 0;
}
.slider .navbar li a {
  color: #fff;
  padding: 5px;
  text-decoration: none;
  -webkit-text-shadow: 2px 2px 8px #000;
  -moz-text-shadow: 2px 2px 8px #000;
  -ms-text-shadow: 2px 2px 8px #000;
  -o-text-shadow: 2px 2px 8px #000;
  text-shadow: 2px 2px 8px #000;
}
.slider .navbar li a:hover,
.slider .navbar li a:focus {
  color: #fff;
}

.slider .navbar ul span {
  position: relative;
  left: -48px;
  top: -26px;
  float: right;
  width: 150px;
  text-align: right;
  padding-right: 12px;
  -webkit-transition: 0.5s;
  -moz-transition: 0.5s;
  -ms-transition: 0.5s;
  transition: 0.5s;
}

.slider .navbar ul span a {
  color: #fff;
  -webkit-transition: 0.5s;
  -moz-transition: 0.5s;
  -ms-transition: 0.5s;
  transition: 0.5s;
}

.slider .navbar #menu ul .item:hover .menu-item {
  padding-right: 14px;
  -webkit-transition: 0.5s;
  -moz-transition: 0.5s;
  -ms-transition: 0.5s;
  transition: 0.5s;
}

/* logo */
.slider .navbar .brand h4 {
  font-size: 30px;
  font-weight: bold;
  color: #fff;
  letter-spacing: 2px;
  text-shadow: 6px 6px 12px #000;
  margin: 40px auto 10px;
}

.slider .navbar .brand h4 span {
  color: #ef255f;
}

.navbar-brand {
  padding: 30px 0 0 0px;
}
.navbar-brand > img {
  padding: 0px;
  width: 70px;
}

/*----------------------------------------------
*   05. About Me
*----------------------------------------------*/
.about {
  padding: 80px 0 80px;
}
.about .img-text {
  background: #141416;
  padding: 80px 0 75px;
  border-radius: 10px 0 10px 70px;
}

.about .img-text img {
  width: 250px;
  height: 250px;
  border: 3px solid #fff;
  border-radius: 50%;
  margin: 0 auto;
  box-shadow: 6px 6px 35px #000;
}

.about .img-text .Continue {
  padding: 60px 0 0;
  width: 250px;
  margin: auto;
}

.about .img-text .Continue ul {
  padding-left: 30px;
}
.about .img-text .Continue ul li h4 {
  color: #fff;
  letter-spacing: 1px;
  line-height: 20px;
}

.about .img-text .Continue ul.soial-media {
  position: absolute;
  bottom: 0;
  margin-bottom: 0;
}
.about .img-text .Continue ul.soial-media li {
  font-size: 18px;
  color: #fff;
  float: left;
  width: 40px;
  height: 40px;
  text-align: center;
  line-height: 40px;
  display: inline-block;
}

.about .content {
  // padding-left: 50px;
}

.about .content h3 {
  padding-top: 30px;
}

.about .content h4 {
  padding-top: 11.5px;
  font-size: 1.2rem;
  font-family: "Open Sans", cursive;
}

.heading-section {
  // font-family: "Abril Fatface", cursive;
}

.about .content span {
  font-size: 18px;
  font-weight: 500;
  line-height: 27px;
  text-align: justify;
}

.about .content p {
  padding-top: 30px;
}

.about .content .skill {
  height: 1em;
  min-width: 100%;
}

.about .content .skill2 {
  height: 1em;
  min-width: 100%;
}

.about .content .skill span {
  background: #ef255f;
  border-radius: 2px;
  // border: 1px solid rgba(255,255,255,0.7);
  // width: 8.1%;
  height: 8px;
  display: inline-block;
  margin: 0 1px;
  flex: 1;
  -moz-flex: 1;
  -webkit-flex: 1;
}

.about .content .skill2 span {
  background: #fccf4d;
  border-radius: 2px;
  // border: 1px solid rgba(255,255,255,0.7);
  // width: 8.1%;
  height: 8px;
  display: inline-block;
  margin: 0 1px;
  flex: 1;
  -moz-flex: 1;
  -webkit-flex: 1;
}

.about .content .show-0 span:nth-child(n + 1),
.about .content .show-1 span:nth-child(n + 2),
.about .content .show-2 span:nth-child(n + 3),
.about .content .show-3 span:nth-child(n + 4),
.about .content .show-4 span:nth-child(n + 5),
.about .content .show-5 span:nth-child(n + 6),
.about .content .show-6 span:nth-child(n + 7),
.about .content .show-7 span:nth-child(n + 8),
.about .content .show-8 span:nth-child(n + 9),
.about .content .show-9 span:nth-child(n + 10),
.about .content .show-10 span:nth-child(n + 11) {
  background: #000;
  .dark-mode & {
    background: rgba(168, 179, 207, 0.2);
  }
}

/*----------------------------------------------
*   06. Services
*----------------------------------------------*/
.section-services {
  background: url(../image/bg_code.jpg) no-repeat;
  /* background: linear-gradient(rgba(0, 0, 0, 0.0), rgba(0, 0, 0, 0.0)),
                url('../image/hero_pattern.png') no-repeat center; */
  background-position: center right;
  background-attachment: fixed;
  background-size: cover;
  position: relative;
}

.section-services .overlay-parallax {
  padding: 80px 0 80px;
  background: rgba(0, 0, 0, 0.6);
  width: 100%;
  height: 100%;
}

// .section-services .overlay-parallax .services-row {
//   margin-top: 100px;
// }

.section-services .overlay-parallax .block-service {
  color: #fff;
  // background: #ef255f;
  // padding: 25px;
  height: 168px;
  margin-bottom: 50px;
}

.section-services .overlay-parallax .block-service2 {
  color: #fff;
  // background: #fccf4d;
  // padding: 25px;
  height: 168px;
  margin-bottom: 50px;
}

.section-services .overlay-parallax .border1 {
  border-radius: 70px 0 0;
}

.section-services .overlay-parallax .border2 {
  border-radius: 0 0 0 70px;
}

.section-services .overlay-parallax .border3 {
  border-radius: 0 70px 0 0;
}

.section-services .overlay-parallax .border22 {
  border-radius: 0 0px 0 0;
}

.section-services .overlay-parallax .border4 {
  border-radius: 0 0 70px 0;
}

.section-services .overlay-parallax .border44 {
  border-radius: 0 0 0px 0;
}

.section-services .overlay-parallax p:not(.sub-title) {
  margin: 0;
}

.section-services .overlay-parallax .border1 p,
.section-services .overlay-parallax .border1 h4,
.section-services .overlay-parallax .border2 p,
.section-services .overlay-parallax .border2 h4,
.section-services .overlay-parallax .border3 p,
.section-services .overlay-parallax .border3 h4,
.section-services .overlay-parallax .border4 p,
.section-services .overlay-parallax .border4 h4 {
  color: #fff;
}

.section-services .overlay-parallax .block-service .icon i {
  // font-size: 55px;
  color: #fccf4d;
  line-height: 115px;
  text-align: center;
  display: block;
  border-radius: 50%;
  margin: 0 auto;
}

.section-services .overlay-parallax .block-service2 .icon i {
  // font-size: 55px;
  color: #ef255f;
  // line-height: 115px;
  text-align: center;
  display: block;
  border-radius: 50%;
  margin: 0 auto;
}
/*----------------------------------------------
*   07. My Work
*----------------------------------------------*/
.section-work {
  padding: 80px 0 80px;
}

.section-work .button-mywork {
  text-align: center;
}

.section-work .button-mywork button.work {
  color: #ef255f !important;
  padding: 8px;
  margin: 8px;
}

.section-work .button-mywork button.active {
  color: #fff !important;
  background: #ef255f;
}

.section-work .button-mywork button.work:hover {
  color: #fff !important;
}

.section-work .img-work .photo {
  position: relative;
  display: block;
  overflow: hidden;
  margin-top: 30px;
  width: 100%;
  max-height: 200px;
}

.section-work .img-work .photo img {
  // width: 300px;

  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  margin: auto;
}

.section-work .img-work .photo .overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  top: 0;
  right: 0;
  background: rgba(255, 43, 66, 0.8);
  width: 100%;

  opacity: 0;
  -webkit-transition: opacity 1s;
  -moz-transition: opacity 1s;
  -ms-transition: opacity 1s;
  transition: opacity 1s;
}

.react-fancybox:before {
  position: absolute;
  bottom: 0;
  left: 0;
  top: 0;
  right: 0;
  background: rgba(255, 43, 66, 0.8);
  width: 100%;

  opacity: 0;
  -webkit-transition: opacity 1s;
  -moz-transition: opacity 1s;
  -ms-transition: opacity 1s;
  transition: opacity 1s;
}

.react-fancybox:after {
  content: "View Image";
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.react-fancybox:hover {
  &::after {
    opacity: 1;
  }
}

.section-work .img-work .photo:hover .overlay {
  opacity: 1;
}

.section-work .img-work .photo:hover img {
  -webkit-transform: scale(1.3);
  -moz-transform: scale(1.3);
  -ms-transform: scale(1.3);
  transform: scale(1.3);
}

.section-work .img-work .photo .zoom {
  display: table;
  width: 100%;
  height: 100%;
}
.section-work .img-work .photo .zoom p {
  color: #fff;
  text-transform: uppercase;
  letter-spacing: 2px;
  vertical-align: middle;
  text-align: center;
  display: table-cell;
}

.section-work .img-work .mix {
  // display: none;
}

/*----------------------------------------------
*   08. quotations
*----------------------------------------------*/
.section-quotations {
  background: url(../image/curls.svg), linear-gradient(#fdcc52, #fdc539);
  background-position: center center;
  background-attachment: fixed;
  background-size: 300%;
  position: relative;
  padding: 80px 0 80px;
}

.section-quotations .quotations h2 {
  color: #fff;
}

.section-quotations .quotations p.sub-title {
  color: #fff;
}
.section-quotations .quotations .quotations-carousel {
  margin-top: 30px;
}

.section-quotations .quotations .quotations-carousel .quote .quote-img {
  position: relative;
  background: #efefef;
  padding: 20px 20px 20px;
  border-radius: 0 0 20px 20px;
  margin-top: 40px;
}

.section-quotations .quotations .quotations-carousel .quote .quote-img:before {
  content: " ";
  position: absolute;
  top: -34px;
  left: 0;
  width: 100%;
  height: 34px;
  background: #ef255f;
  border-radius: 20px 20px 0 0;
}

.section-quotations .quotations .quotations-carousel .quote .quote-img:after {
  content: " ";
}
.section-quotations .quotations .quotations-carousel .quote .quote-img img {
  display: block;
  margin: 0 auto;
}

.section-quotations .quotations .quotations-carousel .quote .quote-img h3 {
  text-align: center;
  margin-top: 20px;
}

.section-quotations .quotations .quotations-carousel .quote .quote-img h5 {
  font-weight: bold;
  text-align: center;
}

.section-quotations .quotations .quotations-carousel .quote .quote-text {
  padding-left: 60px;
  padding-top: 75px;
}

.section-quotations .quotations .quotations-carousel .quote .quote-text p {
  color: #fff;
  letter-spacing: 0.5px;
  text-align: justify;
  padding-left: 70px;
  padding-right: 70px;
  font-size: 36px;
  line-height: 50px;
  font-weight: bold;
  word-spacing: 0.5px;
}

.section-quotations
  .quotations
  .quotations-carousel.owl-carousel
  button.owl-dot {
  background-color: rgba(239, 37, 95, 0.2);
  &.active {
    background-color: rgba(239, 37, 95, 1);
  }
}
.section-quotations .quotations .quotations-carousel .quote .quote-text i {
  color: #efefef;
}

.section-quotations
  .quotations
  .quotations-carousel
  .quote
  .quote-text
  .icon-left {
  text-align: left;
}

.section-quotations
  .quotations
  .quotations-carousel
  .quote
  .quote-text
  .icon-right {
  text-align: right;
}

.section-quotations .quotations .owl-dots {
  text-align: center;
  margin-top: 50px;
}
.owl-carousel .owl-dot,
.owl-carousel .owl-nav .owl-next,
.owl-carousel .owl-nav .owl-prev {
  width: 13px;
  height: 13px;
  background: #fff;
  margin: 10px;
  display: inline-block;
  margin: 3px;
  border: 3px solid #fff;
  text-align: center;
  border-radius: 50%;
}
.owl-carousel .owl-dots .active {
  background: #ef255f;
}

/*----------------------------------------------
*   09. Blog
*----------------------------------------------*/
.section-blog {
  padding: 80px 0 80px;
  background: #f1f1f1;
}

.section-blog .blog .posts {
  margin-top: 30px;
}

.section-blog .blog .posts .post {
  overflow: hidden;
}
.section-blog .blog .posts .post img {
  width: 100%;
  border-radius: 20px 20px 0 0;
}

.section-blog .blog .posts .post h3 {
  margin-top: 0;
  color: #333;
}
.section-blog .blog .posts .post span {
  font-size: 13px;
  font-weight: 700;
  color: #666;
  letter-spacing: 1px;
  margin-right: 30px;
}
.section-blog .blog .posts .post span i {
  color: #ef255f;
}

.section-blog .blog .posts .post p {
  padding-top: 10px;
}

.section-blog .blog .posts .post a.read {
  font-weight: 700;
  color: #ef255f;
  letter-spacing: 1px;
}
.section-blog .blog .posts .post a.read i {
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
  transition: all 0.4s ease;
}

.section-blog .blog .posts .post a.read:hover i {
  margin-left: 7px;
}

.section-blog .blog .posts .post .post-content {
  padding: 20px;
  background: #fff;
  border-radius: 0 0 20px 20px;
}

/*----------------------------------------------
*   10. Contact
*----------------------------------------------*/
.section-contact {
  background-image: url(../image/J2.jpg);
  background-position: center center;
  background-attachment: fixed;
  background-size: cover;
  position: relative;
}

.section-contact .overlay-parallax {
  padding: 80px 0 80px;
  background: rgba(0, 0, 0, 0.8);
  width: 100%;
  height: 100%;
}

.section-contact .overlay-parallax p.text {
  font-size: 16px;
  text-align: left;
  margin-bottom: 20px;
  margin-top: 0 !important;
}

.section-contact .overlay-parallax h2,
.section-contact .overlay-parallax h4,
.section-contact .overlay-parallax p {
  color: #fff;
}

.section-contact .overlay-parallax .contact-us p.text {
  margin-top: 55px;
}
.section-contact .overlay-parallax .contact-us .data p i {
  font-size: 25px;
  text-align: center;
  line-height: 50px;
  width: 50px;
  height: 50px;
  border: 1px solid #ef255f;
  margin-right: 10px;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
    0 0 8px rgb(255, 43, 66);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgb(255, 43, 66);
}
.section-contact .overlay-parallax .contact-form form {
  position: relative;
  top: 15px;
  padding-top: 15px;
}

.section-contact .overlay-parallax .contact-form form button i {
  font-size: 34px;
  color: #fff;
  text-align: center;
  line-height: 80px;
  width: 80px;
  height: 80px;
  margin: auto;
  display: block;
  border: 1px solid #ef255f;
}

.section-contact .overlay-parallax .contact-form form .message {
  position: relative;
}
.section-contact .overlay-parallax .contact-form form textarea.form-control {
  height: 190px;
  width: 87.5%;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -me-box-sizing: border-box;
  box-sizing: border-box;
}
.section-contact .overlay-parallax .contact-form form .form-control {
  color: #fff;
  height: 50px;
  border-radius: 0;
  box-shadow: 0 0;
  background-color: transparent;
  border-color: #ef255f;
  resize: vertical;
  margin-bottom: 20px;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
    0 0 8px rgb(255, 43, 66);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgb(255, 43, 66);
}

.section-contact .overlay-parallax .contact-form form .form-control:focus {
  border-color: #ef255f;
  outline: 0;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
    0 0 8px rgb(255, 43, 66);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgb(255, 43, 66);
}

.section-contact
  .overlay-parallax
  .contact-form
  form
  .form-control::placeholder {
  color: #fff;
}

.section-contact .overlay-parallax .contact-form form button {
  position: absolute;
  top: 0;
  right: 0;
  height: 190px;
  background: #ef255f;
  border-radius: 0 20px 20px 0;
  border: none;
}

/*----------------------------------------------
*   11. Footer
*----------------------------------------------*/
footer {
  background: #111;
  padding: 20px 0 20px 0;
  .dark-mode & {
    background-color: #0e1217;
  }
}

footer p {
  margin: 0;
}

footer p a {
  color: #ef255f;
}

footer ul li {
  font-size: 16px;
  float: right;
  margin-left: 20px;
}

footer ul li a {
  color: #888;
  -webkit-transition: color 0.3s ease-in-out;
  -moz-transition: color 0.3s ease-in-out;
  -ms-transition: color 0.3s ease-in-out;
  transition: color 0.3s ease-in-out;
}

footer ul li a:hover {
  color: #ef255f;
}

footer .copyright p {
  color: #888;
}

footer .copyright p a:hover {
  color: #ef255f !important;
}

.seperator {
  background: url(../image/spr.png) no-repeat center center;
  overflow: hidden;
  padding: 20px 0px 30px 0px;
}

.changed {
  -webkit-filter: hue-rotate(180deg);
  filter: hue-rotate(180deg);
}

/*----------------------------------------------
*   Context Menu
*----------------------------------------------*/

.contextMenu {
  position: fixed;
  box-shadow: 0px 2px 10px #999999;
  z-index: 9999;
  color: #d0c7ff;
  border: 1px solid transparent;
  border-right-color: lighten(#3f51b5, 6%);
  border-bottom-color: lighten(#3f51b5, 6%);
  border-radius: 3px;
  background: linear-gradient(145deg, #673ab7, #3f51b5);
  box-shadow: 0 4px 14px -5px #141321;
  &--option {
    padding: 6px 50px 5px 10px;
    min-width: 160px;
    background: #4b4ab6;
    cursor: default;
    font-size: 12px;
    text-align: left;
    &:hover {
      background-color: rgba(white, 0.09);
      color: white;
    }

    &:active {
      background-color: rgba(white, 0.09);
      color: white;
    }

    &__disabled {
      color: #999999;
      pointer-events: none;
    }
  }

  &--seperator {
    width: 100%;
    height: 1px;
    background: #cccccc;
    margin: 0 0 0 0;
  }
}

.fullscreen {
  // width: auto;
  // height: auto !important;
  &.fullscreen-enabled {
    // width: auto;
    // height: auto !important;
  }
  .absolute-scroller {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    overflow-x: hidden;
    overflow-y: auto;
    background: white;
  }
}

@-webkit-keyframes bg-img {
  0% {
    opacity: 0;
    transform: scale(1.1);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes bg-img {
  0% {
    opacity: 0;
    transform: scale(1.1);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

div#tsparticles {
  position: absolute;
  top: 0;
  z-index: 0;
}

.repo-card-icon {
  -webkit-flex: 0 0 auto;
  -moz-flex: 0 0 auto;
  flex: 0 0 auto;
  height: 40px;
  width: 40px;
  .fa {
    color: #000;
    .dark-mode & {
      color: #fff;
    }
  }
}

.repo-card-title {
  font-size: 16px;
  font-weight: 600;
  color: #ef255f !important;
}

.repo-card-lang {
  & > a {
    color: #000;
    font-weight: 600;
  }
}

.repo-card-lang,
.repo-card-star,
.repo-card-fork {
  color: #ef255f !important;
  font-family: "Open Sans", sans-serif;
  div {
    color: #000;
    line-height: 1;
  }
}

.prime-btn {
  color: #ef255f !important;
  padding: 8px;
  margin: 8px;
  border: 1px solid #ef255f;
  i {
    color: rgb(252, 207, 77);
  }
}

.card-text {
  .dark-mode & {
    color: #666;
  }
}

.quick-link-wrapper {
  a.btn {
    position: relative;
    background-color: #000;
    padding: 0.375rem 0.75rem;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    font-size: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    // box-shadow: 0 10px 10px rgb(0 0 0 / 10%);
    cursor: pointer;
    transition: all 0.2s cubic-bezier(0.68, -0.55, 0.265, 1.55);
    border-color: #000;
    &:hover {
      border-color: #ef255f;
    }
    i {
      font-size: 16px;
      color: #fff;
      &:before {
        letter-spacing: 0;
      }
    }
  }
}

.card {
  .dark-mode & {
    background: #1c1f26;
    border-color: rgba(168, 179, 207, 0.2);
  }
}

.repo-section {
  .dark-mode & {
    .content p {
      color: #fff;
    }
    .repo-card-lang div,
    .repo-card-star div,
    .repo-card-fork div {
      color: #fff;
    }
  }
}
