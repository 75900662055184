/*--------------------------------------------------------------
*   Midea Screan max (min-width:768px) and (max-width:1199px)
*--------------------------------------------------------------*/
@media (min-width: 768px) and (max-width: 1199px) {
  /*----------------------------------------------
*   01. General 
*----------------------------------------------*/
  h2 {
    font-size: 32px;
  }

  button.btn {
    padding: 0.7em 1.5em;
  }
  /*----------------------------------------------
*   02. Slider Background and header-content
*----------------------------------------------*/
  .slider .header-content h1 {
    font-size: 3.5rem;
  }

  .slider .header-content h3.text-1 {
    font-size: 30px;
  }

  .slider .header-content span {
    font-size: 24px;
  }

  .slider .header-content #typed h3 {
    font-size: 20px;
  }

  /*----------------------------------------------
*   03. About Me
*----------------------------------------------*/
  // .about .content{
  //     padding-top: 50px;
  // }

  /*----------------------------------------------
*   04. Services
*----------------------------------------------*/

  .section-services .overlay-parallax img {
    margin: 0 auto;
  }

  .section-services .overlay-parallax .icon-text p,
  .section-services .overlay-parallax .icon-text h4 {
    text-align: center;
  }
  .section-services .overlay-parallax .services-row {
    margin-top: 0;
  }

  .services-row {
    .col-md-12 {
      &:nth-child(1) {
        // background-color: #ef255f;
        .icon {
          margin-bottom: 15px;
          i {
            color: #fccf4d;
          }
        }
      }
      &:nth-child(2) {
        // background-color: #fccf4d;
        .icon {
          margin-bottom: 15px;
          i {
            color: #ef255f;
          }
        }
      }
      &.block-service,
      &.block-service2 {
        height: 168px;
      }
    }
  }

  /*----------------------------------------------
*   05. Blog
*----------------------------------------------*/

  .section-blog .blog .posts .post {
    margin-bottom: 40px;
  }

  /*----------------------------------------------
*   06. Footer
*----------------------------------------------*/

  footer p {
    text-align: center;
  }

  footer ul {
    margin: 10px auto;
    text-align: center;
  }

  footer ul li {
    float: inherit;
    display: inline-block;
  }
}

@media (max-width: 1024px) {
  .section-quotations .quotations .quotations-carousel .quote .quote-text {
    padding-top: 30px;
    padding-left: 15px;
  }
}

@media (max-width: 991px) {
  .about .img-text {
    padding: 40px 0 40px;
  }

  .slider .header-content {
    position: relative;
    top: 100px;
    padding-top: 100px;
  }

  /*----------------------------------------------
    *   my edits starts here
    *----------------------------------------------*/

  .about .content span {
    font-size: 18px;
    font-weight: 500;
    line-height: 40px;
    text-align: justify;
  }

  .section-services .overlay-parallax .border1 {
    border-radius: 0px 0 0;
  }

  .section-services .overlay-parallax .border2 {
    border-radius: 0px 0 0;
  }

  .section-services .overlay-parallax .border3 {
    border-radius: 0px 0 0;
  }

  .section-services .overlay-parallax .border4 {
    border-radius: 0px 0 0;
  }

  .services-row {
    .col-md-12 {
      &:nth-child(1) {
        // background-color: #ef255f;
        .icon {
          margin-bottom: 0;
          i {
            color: #fccf4d;
          }
        }
      }
      &:nth-child(2) {
        // background-color: #fccf4d;
        .icon {
          margin-bottom: 0;
          i {
            color: #ef255f;
          }
        }
      }
      &.block-service,
      &.block-service2 {
        height: auto;
      }
    }
  }

  .mydatas {
    display: inline-block !important;

    justify-content: center;
  }

  /*----------------------------------------------
    *   my edits ends here
    *----------------------------------------------*/

  .about .content .skill span {
    // width: 7.8%;
  }

  .about .img-text .Continue {
    padding: 20px 0 0;
  }
  .section-services .overlay-parallax img {
    margin: 40px auto;
  }

  .section-services .overlay-parallax .block-service .icon i {
    line-height: 60px;
  }
}

/*----------------------------------------------
*   Midea Screan max(767px)
*----------------------------------------------*/
@media (max-width: 767px) {
  /*----------------------------------------------
*   01. General 
*----------------------------------------------*/
  h2 {
    font-size: 32px;
  }

  button.btn {
    padding: 0.7em 1.5em;
  }

  /*----------------------------------------------
*   02. Slider Background and header-content
*----------------------------------------------*/
  .slider .header-content h3 {
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .slider .header-content h3.text-1 {
    font-size: 24px;
  }

  .slider .header-content h1 {
    font-size: 3.5rem !important;
  }

  .slider .header-content #typed h3 {
    font-size: 16px;
  }

  .slider .header-content h3.typed-text i,
  .slider .header-content span {
    font-size: 20px;
  }

  .slider .header-content h3.typed-text:before {
    content: "";
    position: absolute;
    background: #000;
    width: 3px;
    height: 16px;
    // margin-top: 4px;
  }

  .quote-item {
    font-size: 18px;
    font-weight: 500;
    line-height: 27px;
    text-align: justify;
  }

  .slider .navbar li a {
    padding: 0;
  }

  .slider .navbar ul span {
    position: relative;
    left: -48px;
    top: -19px;
  }

  /*----------------------------------------------
*   03. About Me
*----------------------------------------------*/
  .about .content {
    padding-top: 50px;
    // padding-left: 30px;
  }

  .about .content .skill span {
    // width: 7.2%;
  }

  /*----------------------------------------------
*   04. Services
*----------------------------------------------*/

  .section-services .overlay-parallax img {
    margin: 40px auto;
  }

  .section-services .overlay-parallax .icon-text p,
  .section-services .overlay-parallax .icon-text h4 {
    text-align: center;
  }

  .section-services .overlay-parallax .block-service .icon i {
    line-height: normal;
  }

  .section-services .overlay-parallax .services-row {
    margin-top: 0;
  }

  .section-quotations .quotations .quotations-carousel .quote .quote-text {
    padding-left: 15px;
  }

  .section-quotations
    .quotations
    .quotations-carousel
    .quote
    .quote-text
    .fa-4x {
    font-size: 3em;
  }

  .services-row {
    .col-md-12 {
      &:nth-child(1) {
        // background-color: #ef255f;
        .icon {
          margin-bottom: 15px;
          i {
            color: #fccf4d;
          }
        }
      }
      &:nth-child(2) {
        // background-color: #fccf4d;
        .icon {
          margin-bottom: 15px;
          i {
            color: #ef255f;
          }
        }
      }
      &.block-service,
      &.block-service2 {
        height: auto;
      }
    }
  }

  /*----------------------------------------------
*   05. quotations
*----------------------------------------------*/
  .section-quotations .quotations .quotations-carousel .quote .quote-text p {
    padding-left: 50px;
    padding-right: 50px;
    font-size: 18px;
    font-weight: 500;
    line-height: 27px;
    text-align: justify;
  }

  .section-quotations {
    padding: 20px 0;
  }

  /*----------------------------------------------
*   06. Blog
*----------------------------------------------*/

  .section-blog .blog .posts .post {
    margin-bottom: 40px;
  }

  /*----------------------------------------------
*   07. Contact
*----------------------------------------------*/

  .section-contact .overlay-parallax .contact-form form textarea.form-control {
    width: 100%;
  }

  .section-contact .overlay-parallax .contact-form form button {
    position: inherit;
    height: auto;
    width: 100%;
    border-radius: 3px;
  }

  .section-contact .overlay-parallax .contact-form form button i {
    font-size: 20px;
    color: #fff;
    width: 50px;
    height: 50px;
    line-height: 50px;
  }

  /*----------------------------------------------
*   08. Footer
*----------------------------------------------*/

  footer p {
    text-align: center;
  }

  footer ul {
    margin: 10px auto;
    text-align: center;
  }

  footer ul li {
    float: inherit;
    display: inline-block;
  }
}

@media (max-width: 480px) {
  .about .content .skill span {
    // width: 9.1%;
  }
  .about .content .skill2 span {
    // width: 9.1%;
  }
}

@media (max-width: 384px) {
  .about .content .skill span {
    // width: 7.2%;
  }

  .slider .header-content h1 {
    font-size: 27px !important;
  }
}
/*----------------------------------------------
*   Midea Screan max(320px)
*----------------------------------------------*/
@media (max-width: 320px) {
  /*----------------------------------------------
*   01. About Me
*----------------------------------------------*/
  .about .content {
    padding-top: 50px;
    // padding-left: 15px;
  }

  .about .content .skill span {
    // min-width: 25px;
  }

  .section-contact .overlay-parallax p {
    text-align: center;
  }

  .section-contact .overlay-parallax .contact-us .data p i {
    display: block;
    margin: 10px auto;
  }
}
