@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,400;0,700;1,600&display=swap");

@import "~bootstrap/scss/bootstrap.scss";

@import "./assets/css/font-awesome-animation.min.css";
@import "./assets/css/aos.css";
@import "./assets/css/font-awesome.min.css";
@import "./assets/css/magnific-popup.css";
@import "./assets/css/style.scss";
@import "./assets/css/media.scss";
